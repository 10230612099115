@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
}

.datepicker div:first-child {
  z-index: 999;
}

.razorpay-payment-button {
  width: 100% !important;
  a {
    width: 100% !important;
  }
}

.PaymentButton {
  height: 80px !important;
}

.PaymentButton svg {
  top: 15px !important;
}

.PaymentButton-text {
  font-size: 36px !important;
  line-height: 58px !important;
}

@media print {
  div {
    break-inside: avoid;
  }

  body {
    width: 800px;
  }
}
